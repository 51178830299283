import React, { useEffect, useState } from 'react';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import lodash from 'lodash';

import './index.scss';
import '../generic-page.scss';
import './characters.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { IAshCharacter } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { AshCharacter } from '../../modules/ash/components/ash-character';
import { SectionHeader } from '../../modules/common/components/section-header';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';

interface IAshCharacterNodes {
  nodes: IAshCharacter[];
}

interface IAshCharacterEntry {
  allCharacters: IAshCharacterNodes;
}

interface IProps {
  data: IAshCharacterEntry;
}

const AshTeaPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '1✦', value: '1' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Bulwark',
            tooltip: 'Bulwark',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_bulwark.webp"
                width={24}
                alt="Bulwark"
              />
            )
          },
          {
            value: 'Ranger',
            tooltip: 'Ranger',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_ranger.webp"
                width={24}
                alt="Ranger"
              />
            )
          },
          {
            value: 'Skirmisher',
            tooltip: 'Skirmisher',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_skirmisher.webp"
                width={24}
                alt="Skirmisher"
              />
            )
          },
          {
            value: 'Striker',
            tooltip: 'Striker',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_striker.webp"
                width={24}
                alt="Striker"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_support.webp"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Tactician',
            tooltip: 'Tactician',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_tactician.webp"
                width={24}
                alt="Tactician"
              />
            )
          },
          {
            value: 'Vanguard',
            tooltip: 'Vanguard',
            image: (
              <StaticImage
                src="../../images/ash/icons/class_vanguard.webp"
                width={24}
                alt="Vanguard"
              />
            )
          }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Corrosion',
            tooltip: 'Corrosion',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_corrosion.webp"
                width={24}
                alt="Corrosion"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_fire.webp"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_ice.webp"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Lightning',
            tooltip: 'Lightning',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_lightning.webp"
                width={24}
                alt="Lightning"
              />
            )
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_physical.webp"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_water.webp"
                width={24}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/ash/icons/ele_wind.webp"
                width={24}
                alt="Wind"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout className={'generic-page characters-ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>Tea Time</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ash/categories/category_tea.webp"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Tea Time</h1>
          <h2>
            Find the character preferences when it comes to drinks in Ash
            Echoes.
          </h2>
          <p>
            Last updated: <strong>12/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} Character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <p>
        Any Formula requires a minimum of 330 score in order to unlock new
        Dialogues. Full Bonus can be achieved with Coziness 4000 + Bond level 9
        or above. There are two types of Formula:{' '}
        <strong>Memoir and Score</strong>. "Memoir" enables the Memoir book,
        while "Score" are high score recipes used to farm bond (the numbers in
        the brackets mean: No Bonus/Full Coziness/Full Bonus).
      </p>
      <div className="employees-filter-bar ash">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`tea-time-container`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <div key={index}>
              <div className={`employees-container ash-cards`}>
                <Card className="avatar-card">
                  <AshCharacter
                    slug={emp.slug}
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              {emp.teaTime ? (
                <div className="tea-time">
                  <div className="tea-time-row header">
                    <div className="team-time-column">Bond Level</div>
                    <div className="team-time-column">Achievement Name</div>
                    <div className="team-time-column">Formula</div>
                    <div className="team-time-column">Type</div>
                  </div>
                  {emp.teaTime
                    .sort((a, b) => (b.order < a.order ? 1 : -1))
                    .map((bond, index) => {
                      return (
                        <div className="tea-time-row" key={index}>
                          <div className="team-time-column level">
                            [{bond.level}]
                          </div>
                          <div className="team-time-column">
                            <span className="on-mobile block">
                              Bond Level & Name
                            </span>
                            <span className="on-mobile">[{bond.level}]</span>{' '}
                            {bond.name}
                          </div>
                          <div className="team-time-column">
                            <span className="on-mobile block">Formula</span>{' '}
                            {bond.formula}
                          </div>
                          <div className="team-time-column">
                            <span className="on-mobile block">Type</span>
                            {bond.score === '-' ? (
                              'Memoir'
                            ) : (
                              <>Score ({bond.score})</>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <>
                  <div className="info-box with-margin">
                    <p>
                      <strong className={`${emp.element}`}>{emp.name}</strong>{' '}
                      <strong>Tea Time information aren't available yet</strong>
                      . They will be added soon!
                    </p>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/ash/no_results.webp"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default AshTeaPage;

export const Head: React.FC = () => (
  <Seo
    title="Tea Time | Ash Echoes | Prydwen Institute"
    description="Find the character preferences when it comes to drinks in Ash Echoes."
    game="ash"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAshCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        element
        teaTime {
          order
          level
          name
          formula
          score
        }
      }
    }
  }
`;
